import React from "react";
import "./Footer.css";
import Github from "../../assets/github.png";
import Instagram from "../../assets/instagram.png";
import Linkedin from "../../assets/linkedin.png";
import Logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <hr />
      <div className="footer">
        <div className="social-links">
          <a href="https://github.com/Banerjeehub">
            {" "}
            <img src={Github} alt="" />
          </a>
          <a href="https://www.instagram.com/mr_banerze/">
            <img src={Instagram} alt="" />
          </a>
          <a href="https://www.linkedin.com/feed/">
            <img src={Linkedin} alt="" />
          </a>
        </div>

        <div className="logo-f">
          <img
            onClick={() => {
              window.location.reload(false);
            }}
            style={{ cursor: "pointer" }}
            className="logo-footer"
            src={Logo}
            alt=""
          />
        </div>
      </div>
      <div className="blur blur-f-1"></div>
      <div className="blur blur-f-2"></div>
    </div>
  );
};

export default Footer;
