import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpend, setMenuOpened] = useState(false);
  return (
    <div className="header">
      <img
      style={{cursor:"pointer"}}
        onClick={() => {
          window.location.reload(false);
        }}
        src={logo}
        alt="logo"
        className="logo"
      />
      {menuOpend === false && mobile === true ? (
        <div
          style={{
            backgroundColor: "transparent",
            padding: "0.5rem",
            borderRadius: "5px",
            cursor: "pointer",
            border: "1px solid white",
          }}
          onClick={() => {
            setMenuOpened(true);
          }}
        >
          <img
            src={Bars}
            alt=""
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
        </div>
      ) : (
        <ul className="header-menu">
          <li>
            <Link
              onClick={() => {
                setMenuOpened(false);
              }}
              activeClass="active"
              to="home"
              span={true}
              smooth={true}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setMenuOpened(false);
              }}
              to="programs"
              span={true}
              smooth={true}
            >
              Programs
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setMenuOpened(false);
              }}
              to="reasons"
              span={true}
              smooth={true}
            >
              Why Us
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setMenuOpened(false);
              }}
              to="Plans"
              span={true}
              smooth={true}
            >
              Plans
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setMenuOpened(false);
              }}
              to="testimonials"
              span={true}
              smooth={true}
            >
              Testimonisals
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
