import React from "react";
import "./Program.css";
import { programsData } from "../../data/programsData";
import rightArrow from "../../assets/rightArrow.png";
import { Link } from "react-scroll";
const Program = () => {
  return (
    <div className="program" id="programs">
      <div className="program-header">
        <span className="outline-text">Explore Our</span>
        <span>Programs</span>
        <span className="outline-text">To shape you</span>
      </div>

      <div className="programs-catagory">
        {programsData.map((programs) => (
          <div className="catagory">
            {programs.image}
            <span>{programs.heading}</span>
            <span>{programs.details}</span>
            <div className="join-now">
              <span>
                <Link to="join" span={true} smooth={true}>
                  Join Now
                </Link>
              </span>
              <img src={rightArrow} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Program;
